import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  quicklinksWrapper: {
    display: 'none',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '1rem 0',
    margin: '0',

    [theme.fn.largerThan('xs')]: {
      display: 'flex'
    },

    [theme.fn.largerThan('sm')]: {
      gap: '1.5rem'
    },

    [theme.fn.largerThan('lg')]: {
      gap: '2rem'
    }
  },

  quicklink: {
    listStyle: 'none',

    [theme.fn.smallerThan('md')]: {
      '&:nth-child(-n+3)': {
        display: 'none'
      }
    }
  },

  a: {
    color: '#444444',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
      color: theme.colors.orange[5]
    }
  }
}));
