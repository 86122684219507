import { useStyles } from './QuickLinks.style';
import { type QuickLinksProps } from './QuickLinks.types';

import { PartialWrapper } from '@/components/PartialWrapper';

export const QuickLinks = ({ links, className }: QuickLinksProps): React.ReactElement => {
  const { classes, cx } = useStyles();

  return (
    <ul className={cx(classes.quicklinksWrapper, className)}>
      {links.map((link, index) => (
        <li className={classes.quicklink} key={index}>
          <a className={classes.a} href={link.url}>
            {link.text}
          </a>
        </li>
      ))}
    </ul>
  );
};

export const QuickLinksWrapped = (props: QuickLinksProps): React.ReactElement => (
  <PartialWrapper>
    <QuickLinks {...props} />
  </PartialWrapper>
);
